<script setup>

useHead({
  title: `Wyniki wyszukiwania`,
  meta: [
    {name: 'description', content: `Samochody w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Szeroki wybór modeli i atrakcyjne rabaty ✔️ Sprawdź!`},
    {name: 'robots', content: 'noindex, follow'}
  ],
});
const route = useRoute();
const searcher = useSearch();

const page = ref(1);
const fullLoaded = ref(false);

const {data: cars} = await useAsyncData(async () => {
  let cars = await searcher.search();
  cars = cars.data;
  console.log('🔴', cars)
  if (!cars.pop || !cars.length || cars.length < 12) fullLoaded.value = true;
  return cars.pop ? cars : [];
})

const carsAvailable = ref(cars.value);

const loadCars = async (page = 1) => {
  let cars = await searcher.search(null, page);
  cars = cars.data?.sort((a, b) => a.price > b.price ? 1 : -1);
  if (!cars.length || cars.length < 12) fullLoaded.value = true;
  return cars;
}

const loadMore = async () => {
  page.value++;
  const newCars = await loadCars(page.value);
  if (newCars.length) {
    carsAvailable.value = carsAvailable.value.concat(newCars);
  }
}

const brand = computed(() => route.params.brand?.toUpperCase())
const searchResultsCount = ref(0);

</script>

<template>
  <Container no-paddings>
    <AppTop @searchResultsCountChange="(v) => searchResultsCount = v"
            no-banner />
    <div>
      <LayoutColumns>
        <LayoutMainCarsColumn>
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
            <MainContentHeader :counter="carsAvailable.length ? searchResultsCount : 0">Wyniki wyszukiwania</MainContentHeader>
          </div>
          <template v-for="car in carsAvailable">
            <CarTile :car="car" />
          </template>
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 mb-6">
            <InViewTrigger v-if="carsAvailable.length && !fullLoaded" @enterView="loadMore">
              <EffectSpinLoader />
            </InViewTrigger>
          </div>
          <template v-if="!carsAvailable.length">
            <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 flex justify-center ">
              <div class="font-extralight">
                <img src="@/assets/images/pulp-fiction-john-travolta.gif" class="h-[300px]" />
                <div class="pt-6"> Hmmm... nie znaleziono żadnego samochodu.</div>
                <div class="pb-6 text-xs">Zmień zakres wyszukiwania</div>
              </div>
            </div>
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn class="mt-20">
          <FormContact />
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>
  <Container v-if="carsAvailable?.length" >
    <SeoMoreAboutModels :cars="carsAvailable" :title="`Poznaj bliżej:`" />
  </Container>

  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
